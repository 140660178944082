.single__product {
  background: #141124;
  border-radius: 10px;
  cursor: pointer;
  animation: animate 1s ease-in;
}

.single__product:hover {
  box-shadow: 5px 5px 10px -5px #f5b70a;
}

.product__content {
  padding: 0px 20px;
  padding-bottom: 15px;
}

.product__content h6 {
  color: #f5b70a;
  text-align: center;
}
.rating span i {
  color: #f76e11;
}

.rating {
  margin-bottom: 10px;
}

.price {
  color: #fff;
}

.price span {
  color: #f5b70a;
  font-size: 1.3rem;
  font-weight: 500;
}

.shopping__icon {
  width: 30px;
  height: 30px;
  border-radius: 5px;
  background: #f76e11;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

@keyframes animate {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}

@media only screen and (max-width: 767px) {
  .product__content h6 {
    font-size: 0.9rem;
  }

  .price {
    font-size: 0.8rem;
  }

  .price span {
    font-size: 0.9rem;
  }
}
