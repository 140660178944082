/* =========== google fonts =========== */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600&display=swap");

/* ======== base style - Sam ======= */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
html {
  background: #0a071a;
}

section,
footer {
  padding: 70px 0px;
}

h1,
h2 {
  font-size: 2rem;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  color: #f5b70a;
}

p {
  font-size: 1rem;
  color: #c4c4c4;
  line-height: 30px;
  font-family: "Rubik", sans-serif;
}

a {
  text-decoration: none;
  color: unset;
}

i {
  color: #fff;
}

@media only screen and (max-width: 767px) {
  section {
    padding: 40px 0px;
  }
}
